// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Loader = require("../../uikit/reason/atoms/Loader/Loader.bs.js");
var Sentry = require("../../reason/Sentry.bs.js");
var PageTitle = require("../../reason/common/PageTitle.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var DashboardHero = require("../../reason/dashboard/DashboardHero.bs.js");
var LoggedInAgent = require("../../reason/common/CurrentUser/LoggedInAgent.bs.js");
var MarkupHelpers = require("../../reason/common/MarkupHelpers/MarkupHelpers.bs.js");
var ClientStatsWidget = require("./ClientStatsWidget.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var ReactErrorBoundary = require("react-error-boundary");
var NotificationWidgetRelay = require("../../modules/Notification/components/NotificationWidgetRelay.bs.js");
var HomeDashboardPageQuery_graphql = require("../../__generated__/HomeDashboardPageQuery_graphql.bs.js");

var convertVariables = HomeDashboardPageQuery_graphql.Internal.convertVariables;

var convertResponse = HomeDashboardPageQuery_graphql.Internal.convertResponse;

var convertWrapRawResponse = HomeDashboardPageQuery_graphql.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, HomeDashboardPageQuery_graphql.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, HomeDashboardPageQuery_graphql.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(HomeDashboardPageQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(HomeDashboardPageQuery_graphql.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(HomeDashboardPageQuery_graphql.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(HomeDashboardPageQuery_graphql.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function HomeDashboardPage$Widgets$WithoutErrorBoundary(props) {
  var data = use(undefined, undefined, undefined, undefined);
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(MarkupHelpers.Container.make, {
                    className: "pt-4 pb-4 md:pt-8 md:pb-8 lg:pt-10 lg:pb-10",
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx(NotificationWidgetRelay.make, {
                                  query: data.fragmentRefs,
                                  showMore: true
                                }),
                            JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(ClientStatsWidget.make, {
                                        query: data.fragmentRefs
                                      }),
                                  className: "mt-8"
                                })
                          ],
                          className: "p-5 bg-white shadow rounded"
                        })
                  })
            });
}

var WithoutErrorBoundary = {
  make: HomeDashboardPage$Widgets$WithoutErrorBoundary
};

function HomeDashboardPage$Widgets(props) {
  return JsxRuntime.jsx(ReactErrorBoundary.ErrorBoundary, {
              FallbackComponent: (function (_error) {
                  return null;
                }),
              children: JsxRuntime.jsx(HomeDashboardPage$Widgets$WithoutErrorBoundary, {}),
              onError: (function (error) {
                  Sentry.captureException(error, "HomeDashboardPage.res", undefined, undefined, undefined);
                })
            });
}

var Widgets = {
  Query: Query,
  WithoutErrorBoundary: WithoutErrorBoundary,
  make: HomeDashboardPage$Widgets
};

function HomeDashboardPage$default(props) {
  return JsxRuntime.jsx(LoggedInAgent.Provider.make, {
              loaderComponent: Caml_option.some(JsxRuntime.jsxs(JsxRuntime.Fragment, {
                        children: [
                          JsxRuntime.jsx(PageTitle.make, {
                                title: "Loading..."
                              }),
                          JsxRuntime.jsx(Loader.make, {})
                        ]
                      })),
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(DashboardHero.make, {}),
                                JsxRuntime.jsx(HomeDashboardPage$Widgets, {})
                              ]
                            })),
                    fallback: Caml_option.some(JsxRuntime.jsx(Loader.make, {}))
                  })
            });
}

var $$default = HomeDashboardPage$default;

exports.Widgets = Widgets;
exports.default = $$default;
exports.__esModule = true;
/* use Not a pure module */
