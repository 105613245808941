// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Icon__Jsx3 = require("../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Next__Atoms = require("../../reason/next/Next__Atoms.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FragmentErrorBoundary = require("../common/ErrorHandling/FragmentErrorBoundary.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var ClientStatsWidget_query_graphql = require("../../__generated__/ClientStatsWidget_query_graphql.bs.js");

var convertFragment = ClientStatsWidget_query_graphql.Internal.convertFragment;

function waitForFragmentData(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, ClientStatsWidget_query_graphql.node, convertFragment, fRef);
}

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(ClientStatsWidget_query_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, ClientStatsWidget_query_graphql.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(ClientStatsWidget_query_graphql.node, convertFragment, fRef);
}

var Fragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  waitForFragmentData: waitForFragmentData,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function noun(clientsType) {
  if (clientsType === "Sellers") {
    return "seller";
  } else {
    return "buyer";
  }
}

function pluralNoun(clientsType) {
  if (clientsType === "Sellers") {
    return "sellers";
  } else {
    return "buyers";
  }
}

function url(clientsType) {
  if (clientsType === "Sellers") {
    return "/sellers/new";
  } else {
    return "/private_buyers/new";
  }
}

function ClientStatsWidget$ClientsCount(props) {
  var count = props.count;
  var clientsType = props.clientsType;
  var noun$1 = noun(clientsType);
  var pluralNoun$1 = pluralNoun(clientsType);
  var url$1 = url(clientsType);
  if (count === 0) {
    return JsxRuntime.jsxs("span", {
                children: [
                  "You have no active " + pluralNoun$1 + ". ",
                  JsxRuntime.jsx(Next__Atoms.Link.make, {
                        href: url$1,
                        children: "Add your " + noun$1 + " to get matches"
                      })
                ]
              });
  }
  if (count >= 3) {
    return JsxRuntime.jsx("span", {
                children: "You have " + count.toString() + " active " + pluralNoun$1 + "."
              });
  }
  var activeNounForm = count === 1 ? noun$1 : pluralNoun$1;
  return JsxRuntime.jsxs("span", {
              children: [
                "You have " + count.toString() + " active " + activeNounForm + ". ",
                JsxRuntime.jsx(Next__Atoms.Link.make, {
                      href: url$1,
                      children: "Add more " + pluralNoun$1 + " to get more matches"
                    })
              ]
            });
}

var ClientsCount = {
  noun: noun,
  pluralNoun: pluralNoun,
  url: url,
  make: ClientStatsWidget$ClientsCount
};

function ClientStatsWidget$WithoutErrorBoundary(props) {
  var match = use(props.query);
  var match$1 = match.clientStatsForCurrentAccountV5;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h3", {
                      children: "My Clients",
                      className: "mb-4 font-medium text-3xl text-gray-800"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(Icon__Jsx3.make, {
                                      icon: "Home",
                                      className: "text-tan-blue-500",
                                      size: 30
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(ClientStatsWidget$ClientsCount, {
                                            clientsType: "Sellers",
                                            count: match$1.sellers
                                          }),
                                      className: "pl-4"
                                    })
                              ],
                              className: "flex items-center py-4"
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(Icon__Jsx3.make, {
                                      icon: "People",
                                      className: "text-tan-blue-500",
                                      size: 30
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(ClientStatsWidget$ClientsCount, {
                                            clientsType: "Buyers",
                                            count: match$1.buyers
                                          }),
                                      className: "pl-4"
                                    })
                              ],
                              className: "flex items-center py-4"
                            })
                      ]
                    })
              ]
            });
}

var WithoutErrorBoundary = {
  make: ClientStatsWidget$WithoutErrorBoundary
};

function ClientStatsWidget(props) {
  return JsxRuntime.jsx(FragmentErrorBoundary.make, {
              fragmentDisplayName: "client stats",
              children: JsxRuntime.jsx(ClientStatsWidget$WithoutErrorBoundary, {
                    query: props.query
                  })
            });
}

var make = ClientStatsWidget;

exports.Fragment = Fragment;
exports.ClientsCount = ClientsCount;
exports.WithoutErrorBoundary = WithoutErrorBoundary;
exports.make = make;
/* Icon__Jsx3 Not a pure module */
